.App {
  min-height: calc(100vh - 60px);
}

.wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -60px;
  /* the bottom margin is the negative value of the footer's height */
}

/* .push must be the same height as .footer */
footer,
.push {
  height: 60px;
}

footer {
  display: flex !important;
  flex-wrap: wrap;
  padding: 15px 65px;
  border-top: 1px solid #6d4591;
  line-height: 25px;
  background-color: #111016;
  font-size: 14px;
  color: #dbdbdb;
  flex-direction: column;
  align-items: center;
  align-content: flex-end;
}

.appHeader {
  position: sticky;
  display: block;
  z-index: 20;
  top: 0;
  right: 0;
  left: 0;
  height: 60px;
  border-bottom: 1px solid #6d4591;
  background-color: #111016;
  margin-bottom: 36px;
}

.appHeader h3 {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.appContent {
  padding-bottom: 150px;
}

img.classIcon {
  height: 24px;
  margin-right: 8px;
  padding-left: 8px;
  background-color: magenta;
  border: 1px solid darkgrey;
}

.kofiBtn {
  margin: 0 auto;
  display: block;
  width: 145px;
  margin-bottom: 35px;
}